import dynamic from 'next/dynamic';
const Accordion = dynamic(() => import('@/components/Accordion/Accordion'));
const ArticleCta = dynamic(() => import('@/components/ArticleCta/ArticleCta'));
const ArticleImage = dynamic(() => import('@/components/ArticleImage/ArticleImage'));
const ArticleImageGallery = dynamic(() => import('@/components/ArticleImageGallery/ArticleImageGallery'));
const ArticleSection = dynamic(() => import('@/components/ArticleSection/ArticleSection'));
const ArticleSummary = dynamic(() => import('@/components/ArticleSummary/ArticleSummary'));
const ArticleText = dynamic(() => import('@/components/ArticleText/ArticleText'));
const ArticleVideo = dynamic(() => import('@/components/ArticleVideo/ArticleVideo'));
const Button = dynamic(() => import('@/components/Buttons/Button'));
const TeaserSection = dynamic(() => import('@/components/TeaserSection/TeaserSection'));
const BuzzwordItem = dynamic(() => import('@/components/BuzzwordItem/BuzzwordItem'));
const ContactPerson = dynamic(() => import('@/components/ContactPerson/ContactPerson'));
const ContentItem = dynamic(() => import('@/components/ContentItem/ContentItem'));
const CookieBanner = dynamic(() => import('@/components/CookieBanner/CookieBanner'));
const CookieElement = dynamic(() => import('@/components/CookieBanner/CookieBanner'));
const CtaButtonGroup = dynamic(() => import('@/components/CtaSection/CtaButtonGroup/CtaButtonGroup'));
const FeaturedArticle = dynamic(() => import('@/components/FeaturedArticle/FeaturedArticle'));
const Filter = dynamic(() => import('@/components/Filter/Filter'));
const FlipCard = dynamic(() => import('@/components/FlipCard/FlipCard'));
const Footer = dynamic(() => import('@/components/Footer/Footer'));
const FooterColumn = dynamic(() => import('@/components/Footer/ui/FooterColumn'));
const FooterRow = dynamic(() => import('@/components/Footer/ui/FooterRow'));
const Menu = dynamic(() => import('@/components/Footer/ui/Menu'));
const Form = dynamic(() => import('@/components/Form/Form'));
const MaxLength = dynamic(() => import('@/components/Form/Validators/MaximumLength'));
const Pattern = dynamic(() => import('@/components/Form/Validators/Pattern'));
const Required = dynamic(() => import('@/components/Form/Validators/Required'));
const FormInputs = dynamic(() => import('@/components/Form/_components/Input/FormInput'));
const OxSection = dynamic(() => import('@/components/OxSection /OxSection'));
const RichText = dynamic(() => import('@/components/RichText/RichText'));
const Section = dynamic(() => import('@/components/Section/Section'));
const UniversalTextBlock = dynamic(() => import('@/components/UniversalTextBlock/UniversalTextBlock'));
const UspSection = dynamic(() => import('@/components/UspSection/UspSection'));
const Article = dynamic(() => import('@/components/content-type/Article/Article'));
const Job = dynamic(() => import('@/components/content-type/Jobs/Job'));
const Projects = dynamic(() => import('@/components/content-type/Project/Project'));
const Route = dynamic(() => import('@/components/content-type/Route/Route'));
const ArticleMinds = dynamic(() => import('@/components/ArticleMindsSection/ArticleMinds/ArticleMinds'));
const ArticleMindsSection = dynamic(() => import('@/components/ArticleMindsSection/ArticleMindsSection'));
const ArticleQuote = dynamic(() => import('@/components/ArticleQuote/ArticleQuote'));
const BuzzwordGrid = dynamic(() => import('@/components/BuzzwordGrid/BuzzwordGrid'));
const ContentSection = dynamic(() => import('@/components/ContentSection'));
const ContentTitle = dynamic(() => import('@/components/ContentTitle/ContentTitle'));
const Crosslink = dynamic(() => import('@/components/Crosslink/Crosslink'));
const CtaButton = dynamic(() => import('@/components/CtaSection/CtaButton/CtaButton'));
const CtaSection = dynamic(() => import('@/components/CtaSection/CtaSection'));
const FeaturedProjectsSection = dynamic(() => import('@/components/FeaturedProjectSection/FeaturedProjectSection'));
const FlipCardGallery = dynamic(() => import('@/components/FlipCardGallery/FlipCardGallery'));
const MinLength = dynamic(() => import('@/components/Form/Validators/MinimumLength'));
const ComplementaryInputs = dynamic(
    () => import('@/components/Form/_components/ComplementaryInputs/ComplementaryInputs'),
);
const JobListing = dynamic(() => import('@/components/JobListing/JobListing'));
const Layout = dynamic(() => import('@/components/Layout/Layout'));
const LoadMoreButton = dynamic(() => import('@/components/LoadMoreButton'));
const MindListing = dynamic(() => import('@/components/MindListing/MindListing'));
const Navbar = dynamic(() => import('@/components/Navbar/Navbar'));
const MenuLink = dynamic(() => import('@/components/Navbar/ui/MenuLink'));
const ProjectListing = dynamic(() => import('@/components/ProjectListing/ProjectListing'));
const QuoteSection = dynamic(() => import('@/components/QuoteSection/QuoteSection'));
const TabGroup = dynamic(() => import('@/components/TabGroup/TabGroup'));
const Page = dynamic(() => import('@/components/content-type/Page/Page'));
const Logo = dynamic(() => import('@/components/Logo/Logo'));
const AnimatedSection = dynamic(() => import('@/components/AnimatedSection/AnimatedSection'));
const Service = dynamic(() => import('@/components/content-type/Services/Service'));
const PillsRow = dynamic(() => import('@/components/PillsRow/PillsRow'));
const Pill = dynamic(() => import('@/components/Pill/Pill'));
const ProjectShowcase = dynamic(() => import('@/components/ProjectShowcase/ProjectShowcase'));
const LogoCarousel = dynamic(() => import('@/components/LogoCarousel/LogoCarousel'));
const Lottie = dynamic(() => import('@/components/Lottie/Lottie'), { ssr: false });

export const components = {
    accordion: Accordion,
    route: Route,
    articleMindsSection: ArticleMindsSection,
    articleMinds: ArticleMinds,
    articleCta: ArticleCta,
    articleQuote: ArticleQuote,
    articleSection: ArticleSection,
    articleSummary: ArticleSummary,
    articleImageGallery: ArticleImageGallery,
    articleImage: ArticleImage,
    articleVideo: ArticleVideo,
    articleText: ArticleText,
    button: Button,
    navbar: Navbar,
    layout: Layout,
    menu: Menu,
    contactPerson: ContactPerson,
    menuLink: MenuLink,
    article: Article,
    projects: Projects,
    buzzwordItem: BuzzwordItem,
    footer: Footer,
    footerColumn: FooterColumn,
    flipCard: FlipCard,
    complementaryInputsForm: ComplementaryInputs,
    buzzwordGrid: BuzzwordGrid,
    contentItem: ContentItem,
    contentSection: ContentSection,
    contentTitle: ContentTitle,
    Crosslink: Crosslink,
    ctaSection: CtaSection,
    form: Form,
    formInput: FormInputs,
    pattern: Pattern,
    ctaButtonGroup: CtaButtonGroup,
    CtaButton: CtaButton,
    featuredArticle: FeaturedArticle,
    featuredProjectSection: FeaturedProjectsSection,
    flipCardGallery: FlipCardGallery,
    jobListing: JobListing,
    loadMoreButton: LoadMoreButton,
    filter: Filter,
    page: Page,
    projectListing: ProjectListing,
    quoteSection: QuoteSection,
    richText: RichText,
    footerRow: FooterRow,
    tabGroup: TabGroup,
    universalTextBlock: UniversalTextBlock,
    uspSection: UspSection,
    section: Section,
    oxSection: OxSection,
    mindListing: MindListing,
    cookieBanner: CookieBanner,
    cookie: CookieElement,
    job: Job,
    required: Required,
    maxLength: MaxLength,
    minLength: MinLength,
    logo: Logo,
    teaserSection: TeaserSection,
    animatedSection: AnimatedSection,
    service: Service,
    pill: Pill,
    pillsRow: PillsRow,
    projectShowcase: ProjectShowcase,
    logoCarousel: LogoCarousel,
    lottie: Lottie,
};
