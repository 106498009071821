import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useEffect, useReducer } from 'react';
import microcopyReducer, { MicrocopyState } from './MicrocopyReducer';

export const MicrocopyContext = createContext({} as MicrocopyState);
export const ActionContext = createContext({});

const setMicrocopy = (children: any, langcode: string | undefined): MicrocopyState | any => {
    if (typeof window !== 'undefined') {
        let microcopy = children!.props.children.props.config?.content?.microcopy[0] ?? {
            articleBy: '',
            loadMoreButton: '',
            featuredArticleButton: '',
            filterDefaultOption: '',
            articleMindsTitle: "Mindnow's involved minds:",
            imageSourceText: '',
            videoSourceText: '',
            involvedAs: '',
            authorTitle: '',
            menu: '',
            close: '',
            back: '',
            footer: {},
            achievements: '',
            readingTimeText: '',
            chapters: '',
            openCardButton: 'Play this card',
        };
        microcopy.footer = children!.props.children.props.config?.content?.components?.[1] ?? {};
        return { microcopy: microcopy };
    } else {
        if (langcode === 'de') {
            return {
                microcopy: {
                    articleBy: 'Artikel von',
                    loadMoreButton: 'Mehr anzeigen',
                    featuredArticleButton: 'Mehr erfahren',
                    filterDefaultOption: 'Alle,',
                    articleMindsTitle: 'Involvierte Minds',
                    imageSourceText: 'Bildquelle:',
                    videoSourceText: 'Videoquelle:',
                    involvedAs: 'Involviert als',
                    authorTitle: 'Einblicke von',
                    menu: 'Menü',
                    close: 'Schliessen',
                    back: 'Back',
                    footer: {},
                    achievements: 'Errungenschaften:',
                    readingTimeText: 'Minuten Lesezeit',
                    chapters: 'Abschnitte',
                },
            };
        }
        return {
            microcopy: {
                articleBy: 'Article by',
                loadMoreButton: 'Load more',
                featuredArticleButton: 'Read',
                filterDefaultOption: 'All,',
                articleMindsTitle: 'Involved Minds:',
                imageSourceText: 'Image Source:',
                videoSourceText: 'Video Source:',
                involvedAs: 'Involved as:',
                authorTitle: 'Section by',
                menu: 'Menu',
                close: 'Close',
                back: 'Back',
                footer: {},
                achievements: 'Achievements:',
                readingTimeText: 'min read',
                chapters: 'Section',
            },
        };
    }
};

export const MicrocopyProvider = ({ children }: PropsWithChildren) => {
    const router = useRouter();
    const initialState = setMicrocopy(children!, router.locale);
    const [state, dispatch] = useReducer(microcopyReducer, initialState);

    useEffect(() => {
        if (initialState.microcopy.articleBy !== state.microcopy.articleBy && initialState.microcopy.articleBy !== '') {
            dispatch({ type: 'update-microcopy', payload: { microcopy: initialState.microcopy } });
        }
    }, [initialState.microcopy, state.microcopy.articleBy, router.locale]);

    return (
        <ActionContext.Provider value={dispatch}>
            <MicrocopyContext.Provider value={state}>{children}</MicrocopyContext.Provider>
        </ActionContext.Provider>
    );
};
